import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";
import styles from "./CategoryItems.module.css";
import { getAllItems } from "../../Features/ItemSlice";
import { getAllCategories } from "../../Features/CategorySlice";

const CategoryItems = () => {
  const dispatch = useDispatch();
  const { categoryId } = useParams();
  const { items, isLoading: itemsLoading } = useSelector((state) => state.item);
  const { categories, isLoading: categoriesLoading } = useSelector(
    (state) => state.category
  );
  const [sortOption, setSortOption] = useState("default");
  const [dataFetched, setDataFetched] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (!dataFetched && (!items.length || !categories.length)) {
        setDataFetched(true);
        await Promise.all([
          dispatch(getAllItems()),
          dispatch(getAllCategories()),
        ]);
      }
    };

    fetchData();
  }, [dispatch, items.length, categories.length, dataFetched]);

  const currentCategory = categories.find((cat) => cat._id === categoryId);

  const getCategoryItems = () => {
    const filteredItems = items
      .filter((item) => item.category._id === categoryId)
      .map((item) => {
        const itemDate = new Date(item.createdAt);
        const now = new Date();
        const threeDaysAgo = new Date(now - 3 * 24 * 60 * 60 * 1000);

        return {
          ...item,
          newArrival: itemDate > threeDaysAgo,
        };
      });

    switch (sortOption) {
      case "priceLowToHigh":
        return [...filteredItems].sort((a, b) => a.price - b.price);
      case "priceHighToLow":
        return [...filteredItems].sort((a, b) => b.price - a.price);
      default:
        return filteredItems;
    }
  };

  const categoryItems = getCategoryItems();

  const handleSortChange = (e) => {
    setSortOption(e.target.value);
  };

  if (itemsLoading || categoriesLoading) {
    return (
      <div className="container py-5 text-center">
        <div className="spinner-border text-success" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <section className={styles.itemsSection}>
      <div className="container py-5">
        <div className="row mb-4">
          <div className="col-12">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/" className="text-decoration-none">
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/categories" className="text-decoration-none">
                    Categories
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {currentCategory?.name || "Category"}
                </li>
              </ol>
            </nav>
          </div>
        </div>

        <div className="row">
          <div className="col-12 text-center mb-5">
            <h2 className="display-5 fw-bold">{currentCategory?.name}</h2>
            <p className="text-muted lead">{currentCategory?.description}</p>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-12 col-md-4 ms-auto">
            <div className="d-flex align-items-center justify-content-end">
              <label className="me-2 text-muted">Sort by:</label>
              <select
                className="form-select form-select-sm w-auto"
                value={sortOption}
                onChange={handleSortChange}
              >
                <option value="default">Default</option>
                <option value="priceLowToHigh">Price: Low to High</option>
                <option value="priceHighToLow">Price: High to Low</option>
              </select>
            </div>
          </div>
        </div>

        <div className="row g-4">
          {categoryItems.map((item) => (
            <div key={item._id} className="col-md-6 col-lg-4">
              <div className={styles.itemCard}>
                <div className={styles.imageWrapper}>
                  <Link to={`/category/${item.category._id}/${item._id}`}>
                    <img
                      src={`${
                        process.env.REACT_APP_API_URL.split("/api/v1")[0]
                      }${item.image}`}
                      alt={item.name}
                      className={styles.itemImage}
                    />
                  </Link>
                  {item.additionalImages?.length > 0 && (
                    <div className={styles.additionalImages}>
                      {item.additionalImages.slice(0, 3).map((img, index) => (
                        <img
                          key={index}
                          src={`${
                            process.env.REACT_APP_API_URL.split("/api/v1")[0]
                          }${img}`}
                          alt={`${item.name} ${index + 1}`}
                          className={styles.thumbnailImage}
                        />
                      ))}
                    </div>
                  )}
                </div>

                <div className={styles.itemContent}>
                  <h3 className={styles.itemTitle}>{item.name}</h3>
                  <p className={styles.itemPrice}>
                    OMR {item.price.toFixed(2)}
                  </p>
                  <p className={styles.itemDescription}>
                    {item.description.length > 100
                      ? `${item.description.substring(0, 100)}...`
                      : item.description}
                  </p>
                  <div className={styles.badges}>
                    {item.bestSeller && (
                      <span className="badge bg-warning me-2">Best Seller</span>
                    )}
                    {item.freeShipping && (
                      <span className="badge bg-info me-2">Free Shipping</span>
                    )}
                    {item.newArrival && (
                      <span className="badge bg-success">New Arrival</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}

          {categoryItems.length === 0 && (
            <div className="col-12 text-center py-5">
              <h3>No items found in this category</h3>
              <Link to="/categories" className="btn btn-outline-primary mt-3">
                Browse Other Categories
              </Link>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default CategoryItems;
