import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../utils/axiosConfig";

const apiUrl = process.env.REACT_APP_API_URL;

// Get all items
export const getAllItems = createAsyncThunk(
  "item/getAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`${apiUrl}/products`);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.msg || "Failed to fetch items"
      );
    }
  }
);

// Create item
export const createItem = createAsyncThunk(
  "item/create",
  async (itemData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`${apiUrl}/products`, itemData);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.msg || "Failed to create item"
      );
    }
  }
);

// Update item
export const updateItem = createAsyncThunk(
  "item/update",
  async ({ id, itemData }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.patch(
        `${apiUrl}/products/${id}`,
        itemData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.msg || "Failed to update item"
      );
    }
  }
);

// Delete item
export const deleteItem = createAsyncThunk(
  "item/delete",
  async (id, { rejectWithValue }) => {
    try {
      await axiosInstance.delete(`${apiUrl}/products/${id}`);
      return id;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.msg || "Failed to delete item"
      );
    }
  }
);

// Upload item image
export const uploadItemImage = createAsyncThunk(
  "item/uploadImage",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `${apiUrl}/products/uploadImage`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.msg || error.message || "Failed to upload image"
      );
    }
  }
);

export const getSingleProduct = createAsyncThunk(
  "item/getSingleProduct",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`${apiUrl}/products/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.msg || "Failed to fetch item"
      );
    }
  }
);

const initialState = {
  items: [],
  selectedItem: null,
  isLoading: false,
  isSuccess: false,
  isError: false,
  message: "",
  totalItems: 0,
  currentPage: 1,
  totalPages: 1,
};

const itemSlice = createSlice({
  name: "item",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
    setSelectedItem: (state, action) => {
      state.selectedItem = action.payload;
    },
    clearSelectedItem: (state) => {
      state.selectedItem = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Get all items cases
      .addCase(getAllItems.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.message = "";
      })
      .addCase(getAllItems.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.items = action.payload.products;
        state.totalItems = action.payload.count;
        state.currentPage = action.payload.currentPage;
        state.totalPages = action.payload.totalPages;
      })
      .addCase(getAllItems.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      // Create item cases
      .addCase(createItem.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.message = "";
      })
      .addCase(createItem.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.items.push(action.payload.product);
        state.message = "Item created successfully";
      })
      .addCase(createItem.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      // Update item cases
      .addCase(updateItem.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.message = "";
      })
      .addCase(updateItem.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        const index = state.items.findIndex(
          (item) => item._id === action.payload.product._id
        );
        if (index !== -1) {
          state.items[index] = action.payload.product;
        }
        state.message = "Item updated successfully";
      })
      .addCase(updateItem.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      // Delete item cases
      .addCase(deleteItem.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.message = "";
      })
      .addCase(deleteItem.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.items = state.items.filter((item) => item._id !== action.payload);
        state.message = "Item deleted successfully";
      })
      .addCase(deleteItem.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      // Upload image cases
      .addCase(uploadItemImage.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.message = "";
      })
      .addCase(uploadItemImage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "Image uploaded successfully";
      })
      .addCase(uploadItemImage.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      // Get single product cases
      .addCase(getSingleProduct.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.message = "";
      })
      .addCase(getSingleProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.selectedItem = action.payload.product;
      })
      .addCase(getSingleProduct.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset, setSelectedItem, clearSelectedItem } = itemSlice.actions;
export default itemSlice.reducer;
