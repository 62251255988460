import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import styles from "./SearchModal.module.css";
import { getAllItems } from "../../../Features/ItemSlice";

const SearchModal = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const { items } = useSelector((state) => state.item);

  useEffect(() => {
    if (isOpen && (!items || items.length === 0)) {
      dispatch(getAllItems());
    }
  }, [isOpen, dispatch, items]);

  useEffect(() => {
    if (searchTerm.trim()) {
      const filtered =
        items?.filter((item) =>
          item.name.toLowerCase().includes(searchTerm.toLowerCase())
        ) || [];
      setSearchResults(filtered);
    } else {
      setSearchResults([]);
    }
  }, [searchTerm, items]);

  return (
    <>
      {isOpen && (
        <div className={styles.modalOverlay} onClick={onClose}>
          <div
            className={styles.modalContent}
            onClick={(e) => e.stopPropagation()}
          >
            <div className={styles.searchHeader}>
              <input
                type="text"
                className="form-control form-control-lg"
                placeholder="Search items..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                autoFocus
              />
              <button className={styles.closeButton} onClick={onClose}>
                <i className="fas fa-times"></i>
              </button>
            </div>

            <div className={styles.searchResults}>
              {searchResults.length > 0 ? (
                searchResults.map((item) => (
                  <Link
                    key={item._id}
                    to={`/category/${item.category._id}/${item._id}`}
                    className={styles.searchItem}
                    onClick={onClose}
                  >
                    <img
                      src={`${
                        process.env.REACT_APP_API_URL.split("/api/v1")[0]
                      }${item.image}`}
                      alt={item.name}
                      className={styles.itemImage}
                    />
                    <div className={styles.itemInfo}>
                      <h5>{item.name}</h5>
                      <small className="text-muted">
                        Category: {item.category.name}
                      </small>
                      <p className={styles.itemPrice}>
                        OMR {item.price.toFixed(2)}
                      </p>
                    </div>
                  </Link>
                ))
              ) : searchTerm ? (
                <div className={styles.noResults}>
                  No items found matching "{searchTerm}"
                </div>
              ) : (
                <div className={styles.searchPrompt}>
                  Start typing to search items...
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SearchModal;
