import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import styles from "./ItemDetails.module.css";
import { getSingleProduct } from "../../Features/ItemSlice";

const ItemDetails = () => {
  const dispatch = useDispatch();
  const { itemId } = useParams();
  const [quantity, setQuantity] = useState(1);
  const [selectedImage, setSelectedImage] = useState(0);

  const { selectedItem: item, isLoading } = useSelector((state) => state.item);

  const { categories } = useSelector((state) => state.category);

  
  useEffect(() => {
    dispatch(getSingleProduct(itemId));
  }, [dispatch, itemId]);

  if (isLoading || !item) {
    return (
      <div className="container py-5 text-center">
        <div className="spinner-border text-success" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }
  const categoryName =
  categories.find((cat) => cat._id === item.category)?.name || "Category";

  const allImages = [item.image, ...(item.additionalImages || [])];

  return (
    <div className={`${styles.itemDetails} container py-5`}>
      {/* Breadcrumb */}
      <nav aria-label="breadcrumb" className="mb-4">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/" className="text-decoration-none">
              Home
            </Link>
          </li>
          <li className="breadcrumb-item">
            <Link
              to={`/category/${item.category}`}
              className="text-decoration-none"
            >
              {categoryName}
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {item.name}
          </li>
        </ol>
      </nav>

      <div className="row">
        {/* Left Column - Image Gallery */}
        <div className="col-md-6 mb-4">
          <div className={styles.mainImage}>
            <img
              src={`${process.env.REACT_APP_API_URL.split("/api/v1")[0]}${
                allImages[selectedImage]
              }`}
              alt={item.name}
              className="img-fluid"
            />
          </div>
          <div className={styles.thumbnailGallery}>
            {allImages.map((image, index) => (
              <div
                key={index}
                className={`${styles.thumbnail} ${
                  selectedImage === index ? styles.active : ""
                }`}
                onClick={() => setSelectedImage(index)}
              >
                <img
                  src={`${
                    process.env.REACT_APP_API_URL.split("/api/v1")[0]
                  }${image}`}
                  alt={`${item.name} ${index + 1}`}
                />
              </div>
            ))}
          </div>
        </div>

        {/* Right Column - Product Details */}
        <div className="col-md-6">
          <h1 className={styles.itemTitle}>{item.name}</h1>
          <div className={styles.itemPrice}>
            <span className="price">OMR {item.price.toFixed(2)}</span>
          </div>

          <div className={styles.description}>
            <p>{item.description}</p>
          </div>

          <div className={styles.badges}>
            {item.bestSeller && (
              <span className="badge bg-warning me-2">Best Seller</span>
            )}
            {item.freeShipping && (
              <span className="badge bg-info me-2">Free Shipping</span>
            )}
            {item.newArrival && (
              <span className="badge bg-success">New Arrival</span>
            )}
          </div>

          <div className={styles.addToCart}>
            <div className={styles.quantityControl}>
              <button
                onClick={() => setQuantity(Math.max(1, quantity - 1))}
                className="btn btn-outline-secondary"
              >
                -
              </button>
              <input
                type="number"
                value={quantity}
                onChange={(e) =>
                  setQuantity(Math.max(1, parseInt(e.target.value) || 1))
                }
                className="form-control text-center"
                min="1"
              />
              <button
                onClick={() => setQuantity(quantity + 1)}
                className="btn btn-outline-secondary"
              >
                +
              </button>
            </div>
            <button className="btn btn-success w-100 mt-3">Add to cart</button>
          </div>

          <div className={styles.guarantee}>
            <i className="fas fa-shield-alt me-2"></i>
            30 days money back guarantee
          </div>

          <div className={styles.tabs}>
            <ul className="nav nav-tabs" role="tablist">
              <li className="nav-item">
                <button
                  className="nav-link active"
                  data-bs-toggle="tab"
                  data-bs-target="#details"
                >
                  Details
                </button>
              </li>
              <li className="nav-item">
                <button
                  className="nav-link"
                  data-bs-toggle="tab"
                  data-bs-target="#packaging"
                >
                  Packaging
                </button>
              </li>
              <li className="nav-item">
                <button
                  className="nav-link"
                  data-bs-toggle="tab"
                  data-bs-target="#shipping"
                >
                  Shipping details
                </button>
              </li>
            </ul>
            <div className="tab-content pt-3">
              <div className="tab-pane fade show active" id="details">
                <p>{item.description}</p>
              </div>
              <div className="tab-pane fade" id="packaging">
                <p>We will pack your order as you wish</p>
              </div>
              <div className="tab-pane fade" id="shipping">
                <p>Shipping will cost you money </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemDetails;
