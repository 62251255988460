import * as yup from "yup";

// Password regex: at least 10 characters long, contains at least one uppercase letter, and one symbol
const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{10,})/;

// Email regex
const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

// username have no special characters
const usernameRegex = /^[a-zA-Z0-9]+$/;

export const RegistrationValidations = yup.object().shape({
  username: yup
    .string()
    .required("Username cannot be empty")
    .matches(usernameRegex, "Username can only contain letters and numbers")
    .min(3, "Username must be at least 3 characters long")
    .max(20, "Username cannot be more than 20 characters long"),
  email: yup
    .string()
    .required("Email cannot be empty")
    .matches(emailRegex, "Incorrect Email Format"),

  password: yup
    .string()
    .required("Password cannot be empty")
    .min(10, "Password must be at least 10 characters long")
    .matches(/.*[A-Z].*/, "Password must contain at least one uppercase letter")
    .matches(/.*[!@#$%^&*]/, "Password must contain at least one symbol"),

  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password cannot be empty"),
});
