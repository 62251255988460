import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllCategories } from "../../Features/CategorySlice";
import styles from "./Categories.module.css";
import { Link } from "react-router-dom";

const Categories = () => {
  const dispatch = useDispatch();
  const { categories, isLoading } = useSelector((state) => state.category);

  useEffect(() => {
    dispatch(getAllCategories());
  }, [dispatch]);

  if (isLoading) {
    return (
      <div className="container py-5 text-center">
        <div className="spinner-border text-success" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <section className={styles.categorySection}>
      <div className="container py-5">
        <div className="row">
          <div className="col-12 text-center mb-5">
            <h2 className="display-5 fw-bold">Our Categories</h2>
            <p className="text-muted lead">
              Discover our wide range of plant categories
            </p>
          </div>
        </div>

        {isLoading ? (
          <div className="text-center py-5">
            <div className="spinner-border text-success" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <div className="row g-4">
            {categories.map((category) => (
              <div key={category._id} className="col-md-6 col-lg-4">
                <Link
                  to={`/category/${category._id}`}
                  className={styles.categoryCard}
                >
                  <div className={styles.imageWrapper}>
                    <img
                      src={`${
                        process.env.REACT_APP_API_URL.split("/api/v1")[0]
                      }${category.image}`}
                      alt={category.name}
                      className={styles.categoryImage}
                    />
                  </div>
                  <div className={styles.categoryContent}>
                    <h3 className={styles.categoryTitle}>{category.name}</h3>
                    <p className={styles.categoryDescription}>
                      {category.description}
                    </p>
                    <span className={styles.exploreLink}>
                      Explore Category{" "}
                      <i className="fas fa-arrow-right ms-2"></i>
                    </span>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  );
};

export default Categories;
