import React, { useState, useEffect } from "react";
import styles from "./Hero.module.css";
import banner1 from "../../../images/banner.png";
import banner2 from "../../../images/banner2.png";
const sliderData = [
  {
    id: 1,
    title: "Plant pot flower isolated on transparent background",
    description: "Plant pot flower isolated on transparent background",
    image: banner1,
    price: "25 OMR",
  },
  {
    id: 2,
    title: "Beautiful succulent in ceramic pot",
    description: "Perfect for your home or office decoration",
    image: banner1,
    price: "30 OMR",
  },
  {
    id: 3,
    title: "Elegant indoor palm plant",
    description: "Bring nature into your living space",
    image: banner1,
    price: "45 OMR",
  },
];

const stats = [
  {
    count: "50,000",
    label: "Customers",
  },
  {
    count: "30,000",
    label: "Fertilizer Types",
  },
  {
    count: "3,000",
    label: "Plant Varieties",
  },
];

function Hero() {
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % sliderData.length);
  };

  const prevSlide = () => {
    setCurrentSlide(
      (prev) => (prev - 1 + sliderData.length) % sliderData.length
    );
  };

  useEffect(() => {
    const timer = setInterval(nextSlide, 5000);
    return () => clearInterval(timer);
  }, []);

  return (
    <>
      <section className={`bg-light py-5 ${styles.heroSection}`}>
        <div className="container">
          <div className="row align-items-center min-vh-75 g-4">
            <div className="col-lg-5">
              <div className="pe-lg-4">
                <h1 className="display-4 fw-bold mb-4 lh-sm">
                  {sliderData[currentSlide].title}
                </h1>
                <p className="lead text-secondary mb-4">
                  {sliderData[currentSlide].description}
                </p>
                <button className="btn btn-success btn-lg px-4 py-2">
                  Add Now
                </button>
              </div>
            </div>

            <div className="col-lg-7">
              <div className="position-relative p-4">
                <div
                  className={`${styles.productDisplay} bg-white rounded-4 p-4`}
                >
                  <img
                    src={sliderData[currentSlide].image}
                    alt={sliderData[currentSlide].title}
                    className="img-fluid"
                  />
                  <div
                    className={`${styles.productInfo} bg-white rounded-3 p-4 shadow-sm`}
                  >
                    <h3 className="text-success fs-4 fw-semibold mb-2">
                      {sliderData[currentSlide].title}
                    </h3>
                    <p className="text-success fs-3 fw-bold mb-0">
                      {sliderData[currentSlide].price}
                    </p>
                  </div>
                </div>

                <div className="position-absolute bottom-0 end-0 p-3 d-flex gap-2">
                  <button
                    className="btn btn-light rounded-circle shadow-sm d-flex align-items-center justify-content-center"
                    onClick={prevSlide}
                    aria-label="Previous slide"
                    style={{ width: "40px", height: "40px" }}
                  >
                    <i className="fas fa-arrow-left"></i>
                  </button>
                  <button
                    className="btn btn-light rounded-circle shadow-sm d-flex align-items-center justify-content-center"
                    onClick={nextSlide}
                    aria-label="Next slide"
                    style={{ width: "40px", height: "40px" }}
                  >
                    <i className="fas fa-arrow-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* New Nature Banner Section */}
      <section className="py-5">
        <div className="container">
          <div className="row align-items-center g-5">
            <div className="col-lg-6 position-relative">
              <div className="position-relative">
                <div
                  className="border border-success border-2 position-absolute"
                  style={{
                    top: "20px",
                    left: "20px",
                    width: "80%",
                    height: "100%",
                    zIndex: 0,
                  }}
                />
                <img
                  src={banner2}
                  alt="Nature's Beauty"
                  className="img-fluid position-relative rounded-2"
                  style={{ zIndex: 1 }}
                />
              </div>
            </div>

            <div className="col-lg-6">
              <div className="ps-lg-4">
                <h2 className="display-4 fw-bold mb-4">
                  Bringing Nature's Beauty to Your Doorstep
                </h2>
                <p className="lead text-secondary mb-4 text-center">
                  At our flower and plant shop, we believe in the transformative
                  power of nature. Our mission is to fill your life with the
                  vibrant colors and soothing presence of flowers and plants. We
                  are committed to offering the finest quality products and
                  exceptional customer service, ensuring that every interaction
                  with us is a delightful experience.
                </p>

                <div className="row g-4 mb-4">
                  {stats.map((stat, index) => (
                    <div key={index} className="col-md-4">
                      <div className="bg-light p-3 text-center rounded">
                        <div className="fs-4 fw-bold">{stat.count}</div>
                        <div className="text-secondary">{stat.label}</div>
                      </div>
                    </div>
                  ))}
                </div>

                <button className="btn btn-success btn-lg px-4 py-2">
                  Discover Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Hero;
