import React, { useState, useEffect } from "react";
import styles from "./Product.module.css";
import { showErrorToast, showSuccessToast } from "../../../utils/Toastr";

import { getAllCategories } from "../../../Features/CategorySlice";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
  clearSelectedItem,
  createItem,
  deleteItem,
  getAllItems,
  reset,
  setSelectedItem,
  updateItem,
  uploadItemImage,
} from "../../../Features/ItemSlice";

const Product = () => {
  const dispatch = useDispatch();
  const { items, isLoading, isSuccess, isError, message, selectedItem } =
    useSelector((state) => state.item);
  const { categories } = useSelector((state) => state.category);

  const [formData, setFormData] = useState({
    name: "",
    price: 0,
    description: "",
    image: "/uploads/seed2.jpg",
    additionalImages: [],
    category: "",
    bestSeller: false,
    freeShipping: false,
    inventory: 15,
  });

  const [mainImageFile, setMainImageFile] = useState(null);
  const [additionalImageFiles, setAdditionalImageFiles] = useState([]);
  const [mainImagePreview, setMainImagePreview] = useState(null);
  const [additionalImagePreviews, setAdditionalImagePreviews] = useState([]);

  useEffect(() => {
    dispatch(getAllItems());
    dispatch(getAllCategories());
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      showErrorToast(message);
    }
    if (isSuccess && message) {
      showSuccessToast(message);
    }
    dispatch(reset());
  }, [isError, isSuccess, message, dispatch]);

  useEffect(() => {
    if (selectedItem) {
      setFormData({
        ...selectedItem,
        category:
          selectedItem.category.$oid ||
          selectedItem.category._id ||
          selectedItem.category,
        bestSeller: selectedItem.bestSeller,
        freeShipping: selectedItem.freeShipping,
        price: Number(selectedItem.price),
        inventory: Number(selectedItem.inventory),
        additionalImages: selectedItem.additionalImages || [],
      });

      setMainImagePreview(
        selectedItem.image
          ? `${process.env.REACT_APP_API_URL.split("/api/v1")[0]}${
              selectedItem.image
            }`
          : null
      );

      setAdditionalImagePreviews(
        (selectedItem.additionalImages || []).map(
          (img) => `${process.env.REACT_APP_API_URL.split("/api/v1")[0]}${img}`
        )
      );
    }
  }, [selectedItem]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]:
        type === "checkbox"
          ? checked
          : type === "number"
          ? parseFloat(value) || 0
          : value,
    }));
  };

  const handleMainImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setMainImageFile(file);
      setMainImagePreview(URL.createObjectURL(file));
    }
  };

  const handleAdditionalImagesChange = (e) => {
    const files = Array.from(e.target.files);
    setAdditionalImageFiles(files);

    const previews = files.map((file) => URL.createObjectURL(file));
    setAdditionalImagePreviews(previews);
  };

  const uploadImages = async () => {
    let uploadedMainImage = formData.image;
    let uploadedAdditionalImages = [...(formData.additionalImages || [])];

    try {
      if (mainImageFile) {
        const formDataMain = new FormData();
        formDataMain.append("file", mainImageFile);
        const mainImageResponse = await dispatch(
          uploadItemImage(formDataMain)
        ).unwrap();
        uploadedMainImage = mainImageResponse.img;
      }

      if (additionalImageFiles?.length > 0) {
        for (const file of additionalImageFiles) {
          const formDataAdd = new FormData();
          formDataAdd.append("file", file);

          const response = await dispatch(
            uploadItemImage(formDataAdd)
          ).unwrap();
          uploadedAdditionalImages.push(response.img);
        }
      }

      return {
        mainImage: uploadedMainImage,
        additionalImages: uploadedAdditionalImages,
      };
    } catch (error) {
      console.error("Upload error:", error);
      showErrorToast(error.message || "Error uploading images");
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const { mainImage, additionalImages } = await uploadImages();

      const submissionData = {
        ...formData,
        image: mainImage,
        additionalImages: additionalImages,
        category:
          formData.category.$oid || formData.category._id || formData.category,
        price: Number(formData.price),
        inventory: Number(formData.inventory),
        bestSeller: formData.bestSeller,
        freeShipping: formData.freeShipping,
      };
      delete submissionData.newArrival;

      if (selectedItem) {
        await dispatch(
          updateItem({
            id: selectedItem._id,
            itemData: submissionData,
          })
        ).unwrap();
      } else {
        await dispatch(createItem(submissionData)).unwrap();
      }

      setFormData({
        name: "",
        price: 0,
        description: "",
        image: "/uploads/seed2.jpg",
        additionalImages: [],
        category: "",
        bestSeller: false,
        freeShipping: false,
        inventory: 15,
      });
      setMainImageFile(null);
      setAdditionalImageFiles([]);
      setMainImagePreview(null);
      setAdditionalImagePreviews([]);
      dispatch(clearSelectedItem());
      dispatch(getAllItems());
    } catch (error) {
      showErrorToast(error);
    }
  };

  const handleEdit = (item) => {
    dispatch(setSelectedItem(item));
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this item?")) {
      try {
        await dispatch(deleteItem(id)).unwrap();
        dispatch(getAllItems());
      } catch (error) {
        showErrorToast(error);
      }
    }
  };

  const removeAdditionalImage = (index) => {
    setFormData((prev) => ({
      ...prev,
      additionalImages: prev.additionalImages.filter((_, i) => i !== index),
    }));
    setAdditionalImagePreviews((prev) => prev.filter((_, i) => i !== index));
  };

  return (
    <div className={`${styles.layout} container-fluid py-4`}>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/Dashboard" className="text-decoration-none">
              Home
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Products
          </li>
        </ol>
      </nav>
      <div className="row">
        {/* Item Form */}
        <div className="col-12 col-lg-4 mb-4">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title mb-4">
                {selectedItem ? "Edit Item" : "Add New Item"}
              </h5>
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label className="form-label">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    required
                    minLength="3"
                    maxLength="100"
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Price</label>
                  <input
                    type="number"
                    className="form-control"
                    name="price"
                    value={formData.price}
                    onChange={handleInputChange}
                    required
                    min="0"
                    step="0.01"
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Description</label>
                  <textarea
                    className="form-control"
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    required
                    rows="3"
                    maxLength="1000"
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Category</label>
                  <select
                    className="form-select"
                    name="category"
                    value={formData.category}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select Category</option>
                    {categories.map((category) => (
                      <option key={category._id} value={category._id}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mb-3">
                  <label className="form-label">Inventory</label>
                  <input
                    type="number"
                    className="form-control"
                    name="inventory"
                    value={formData.inventory}
                    onChange={handleInputChange}
                    required
                    min="0"
                  />
                </div>
                {/* Main Image */}
                <div className="mb-3">
                  <label className="form-label">Main Image</label>
                  {mainImagePreview && (
                    <div className="mb-2">
                      <img
                        src={mainImagePreview}
                        alt="Main preview"
                        className={styles.imagePreview}
                      />
                    </div>
                  )}
                  <input
                    type="file"
                    className="form-control"
                    onChange={handleMainImageChange}
                    accept="image/*"
                  />
                </div>

                {/* Additional Images */}
                <div className="mb-3">
                  <label className="form-label">Additional Images</label>
                  <div className="mb-2 d-flex flex-wrap gap-2">
                    {additionalImagePreviews.map((preview, index) => (
                      <div key={index} className="position-relative">
                        <img
                          src={preview}
                          alt={`Preview ${index + 1}`}
                          className={styles.imagePreview}
                        />
                        <button
                          type="button"
                          className="btn btn-danger btn-sm position-absolute top-0 end-0"
                          onClick={() => removeAdditionalImage(index)}
                        >
                          <i className="fas fa-times"></i>
                        </button>
                      </div>
                    ))}
                  </div>
                  <input
                    type="file"
                    className="form-control"
                    onChange={handleAdditionalImagesChange}
                    accept="image/*"
                    multiple
                  />
                </div>
                <div className="mb-3">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      name="bestSeller"
                      checked={formData.bestSeller}
                      onChange={handleInputChange}
                    />
                    <label className="form-check-label">Best Seller</label>
                  </div>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      name="freeShipping"
                      checked={formData.freeShipping}
                      onChange={handleInputChange}
                    />
                    <label className="form-check-label">Free Shipping</label>
                  </div>
                </div>
                <div className="d-grid gap-2">
                  <button
                    type="submit"
                    className="btn btn-success"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : selectedItem ? (
                      "Update Item"
                    ) : (
                      "Add Item"
                    )}
                  </button>
                  {selectedItem && (
                    <button
                      type="button"
                      className="btn btn-outline-secondary"
                      onClick={() => {
                        dispatch(clearSelectedItem());
                        setFormData({
                          name: "",
                          price: 0,
                          description: "",
                          image: "/uploads/seed2.jpg",
                          additionalImages: [],
                          category: "",
                          bestSeller: false,
                          freeShipping: false,
                          inventory: 15,
                        });
                        setMainImagePreview(null);
                        setAdditionalImagePreviews([]);
                      }}
                    >
                      Cancel
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* Items List */}
        <div className="col-12 col-lg-8">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title mb-4">Items</h5>
              {isLoading && !items.length ? (
                <div className="text-center py-4">
                  <div className="spinner-border text-success" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : (
                <div className="table-responsive">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th>Images</th>
                        <th>Name</th>
                        <th>Price</th>
                        <th>Category</th>
                        <th>Inventory</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {items.map((item) => (
                        <tr key={item._id}>
                          <td>
                            <div className="d-flex gap-1">
                              <img
                                src={`${
                                  process.env.REACT_APP_API_URL.split(
                                    "/api/v1"
                                  )[0]
                                }${item.image}`}
                                alt={item.name}
                                className={styles.productImage}
                              />
                              {item.additionalImages?.map((img, index) => (
                                <img
                                  key={index}
                                  src={`${
                                    process.env.REACT_APP_API_URL.split(
                                      "/api/v1"
                                    )[0]
                                  }${img}`}
                                  alt={`${item.name} ${index + 1}`}
                                  className={styles.productImage}
                                />
                              ))}
                            </div>
                          </td>
                          <td>{item.name}</td>
                          <td>OMR {item.price.toFixed(2)}</td>
                          <td>{item.category?.name}</td>
                          <td>{item.inventory}</td>
                          <td>
                            <button
                              className="btn btn-sm btn-outline-primary me-2"
                              onClick={() => handleEdit(item)}
                            >
                              <i className="fas fa-edit"></i>
                            </button>
                            <button
                              className="btn btn-sm btn-outline-danger"
                              onClick={() => handleDelete(item._id)}
                            >
                              <i className="fas fa-trash"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product;
