import React, { useState } from "react";
import logo from "../../../images/logo brown.png";
import { Link, NavLink, useNavigate } from "react-router-dom";
import SearchModal from "../SearchModal/SearchModal";

const Header = () => {
  const navigate = useNavigate();
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  return (
    <>
      <nav className="navbar navbar-expand-lg bg-white py-3">
        <div className="container">
          <Link className="navbar-brand d-flex align-items-center" to="/">
            <img src={logo} alt="Nabat Tech" height="40" className="me-2" />
            <span className="text-success fw-bold">Nabat</span>
            <span className="text-muted">Tech</span>
          </Link>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav mx-auto">
              <li className="nav-item">
                <NavLink
                  to="/"
                  className={({ isActive }) =>
                    `nav-link ${
                      isActive ? "active text-success fw-semibold" : ""
                    }`
                  }
                >
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/category"
                  className={({ isActive }) =>
                    `nav-link ${
                      isActive ? "active text-success fw-semibold" : ""
                    }`
                  }
                >
                  Categories
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/underconstruction"
                  className={({ isActive }) =>
                    `nav-link ${
                      isActive ? "active text-success fw-semibold" : ""
                    }`
                  }
                >
                  Soil Prop
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/underconstruction"
                  className={({ isActive }) =>
                    `nav-link ${
                      isActive ? "active text-success fw-semibold" : ""
                    }`
                  }
                >
                  Community
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/underconstruction"
                  className={({ isActive }) =>
                    `nav-link ${
                      isActive ? "active text-success fw-semibold" : ""
                    }`
                  }
                >
                  FAQ
                </NavLink>
              </li>
            </ul>

            <div className="d-flex align-items-center gap-3">
              <button
                className="btn btn-link text-dark"
                onClick={() => setIsSearchOpen(true)}
              >
                <i className="fa fa-search" size={20} />
              </button>
              <button className="btn btn-link text-dark position-relative">
                <i className="fa-solid fa-cart-shopping" size={20} />
                <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                  0
                </span>
              </button>
              <button
                className="btn btn-link text-dark"
                onClick={() => navigate("/login")}
              >
                <i className="fa-solid fa-user" size={20} />
              </button>
            </div>
          </div>
        </div>
      </nav>
      <SearchModal
        isOpen={isSearchOpen}
        onClose={() => setIsSearchOpen(false)}
      />
    </>
  );
};

export default Header;
