import React, { useState } from "react";
import plant1 from "../../../images/item.png";
import styles from "./ShowCaseItems.module.css";

const categories = [
  { id: 1, name: "Plants", active: true },
  { id: 2, name: "New Arrivals", active: false },
  { id: 3, name: "Best Sellers", active: false },
  { id: 4, name: "Free Shipping", active: false },
  { id: 5, name: "Gardening Tools", active: false },
];

// Extended products array with category information
const allProducts = {
  Plants: [
    {
      id: 1,
      image: plant1,
      discount: "15%",
      title: "Peace Lily Plant",
      description:
        "Perfect indoor plant for purifying air and adding elegance.",
      currentPrice: "25$",
      originalPrice: "40$",
    },
    {
      id: 2,
      image: plant1,
      discount: "25%",
      title: "Snake Plant",
      description: "Low-maintenance plant perfect for beginners.",
      currentPrice: "25$",
      originalPrice: "40$",
    },
    {
      id: 3,
      image: plant1,
      discount: "35%",
      title: "Monstera Deliciosa",
      description: "Tropical plant with unique leaf patterns.",
      currentPrice: "25$",
      originalPrice: "40$",
    },
  ],
  "New Arrivals": [
    {
      id: 4,
      image: plant1,
      discount: "30%",
      title: "Fiddle Leaf Fig",
      description: "Trendy indoor plant with large, glossy leaves.",
      currentPrice: "35$",
      originalPrice: "50$",
    },
    {
      id: 5,
      image: plant1,
      discount: "20%",
      title: "String of Pearls",
      description: "Unique trailing succulent perfect for hanging baskets.",
      currentPrice: "28$",
      originalPrice: "35$",
    },
    {
      id: 6,
      image: plant1,
      discount: "25%",
      title: "Bird of Paradise",
      description: "Exotic plant with stunning flowers.",
      currentPrice: "45$",
      originalPrice: "60$",
    },
  ],
  "Best Sellers": [
    {
      id: 7,
      image: plant1,
      discount: "25%",
      title: "ZZ Plant",
      description: "Popular low-light tolerant indoor plant.",
      currentPrice: "30$",
      originalPrice: "40$",
    },
    {
      id: 8,
      image: plant1,
      discount: "25%",
      title: "Pothos",
      description: "Easy-care vine with heart-shaped leaves.",
      currentPrice: "20$",
      originalPrice: "30$",
    },
    {
      id: 9,
      image: plant1,
      discount: "25%",
      title: "Spider Plant",
      description: "Classic hanging plant with striped leaves.",
      currentPrice: "22$",
      originalPrice: "35$",
    },
  ],
  "Free Shipping": [
    {
      id: 10,
      image: plant1,
      discount: "25%",
      title: "All-Purpose Plant Food",
      description: "Complete nutrition for all indoor plants.",
      currentPrice: "15$",
      originalPrice: "25$",
    },
    {
      id: 11,
      image: plant1,
      discount: "25%",
      title: "Organic Compost",
      description: "Rich in nutrients for healthy plant growth.",
      currentPrice: "18$",
      originalPrice: "30$",
    },
    {
      id: 12,
      image: plant1,
      discount: "25%",
      title: "Succulent Fertilizer",
      description: "Specialized nutrition for succulents and cacti.",
      currentPrice: "12$",
      originalPrice: "20$",
    },
  ],
  "Gardening Tools": [
    {
      id: 13,
      image: plant1,
      discount: "25%",
      title: "Premium Pruning Shears",
      description: "Professional-grade tool for precise cuts.",
      currentPrice: "28$",
      originalPrice: "45$",
    },
    {
      id: 14,
      image: plant1,
      discount: "25%",
      title: "Watering Can",
      description: "Ergonomic design for easy plant care.",
      currentPrice: "20$",
      originalPrice: "35$",
    },
    {
      id: 15,
      image: plant1,
      discount: "25%",
      title: "Plant Care Kit",
      description: "Essential tools for plant maintenance.",
      currentPrice: "40$",
      originalPrice: "65$",
    },
  ],
};

const ShowCaseItems = () => {
  const [activeCategory, setActiveCategory] = useState("Plants");
  const [isAnimating, setIsAnimating] = useState(false);

  const handleCategoryChange = (categoryName) => {
    setIsAnimating(true);
    setActiveCategory(categoryName);
    // Reset animation state after transition
    setTimeout(() => setIsAnimating(false), 500);
  };

  return (
    <section className="py-5">
      <div className="container px-md-4 px-3">
        <div className="d-flex justify-content-center mb-4 mb-md-5">
          <div className={`${styles.categories} px-2`}>
            {categories.map((category) => (
              <button
                key={category.id}
                className={`${styles.categoryBtn} ${
                  activeCategory === category.name ? styles.active : ""
                }`}
                onClick={() => handleCategoryChange(category.name)}
              >
                {category.name}
              </button>
            ))}
          </div>
        </div>

        {/* Products Grid */}
        <div
          className={`row g-3 g-md-4 ${
            isAnimating ? styles.fadeTransition : ""
          }`}
        >
          {allProducts[activeCategory].map((product) => (
            <div key={product.id} className="col-md-6 col-lg-4">
              <div className={`${styles.productCard} mx-2 mx-md-0`}>
                {/* Product Image */}
                <div className={styles.imageWrapper}>
                  <div className={styles.discountBadge}>
                    Save {product.discount}
                  </div>
                  <img
                    src={product.image}
                    alt={product.title}
                    className="img-fluid"
                  />
                </div>

                {/* Product Info */}
                <div className="text-center p-4">
                  <h3 className="fs-5 fw-semibold mb-2">{product.title}</h3>
                  <p className="text-secondary small mb-3">
                    {product.description}
                  </p>
                  <div className={styles.priceWrapper}>
                    <span className={styles.currentPrice}>
                      {product.currentPrice}
                    </span>
                    <span className={styles.originalPrice}>
                      {product.originalPrice}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ShowCaseItems;
